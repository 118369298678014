import { useEffect } from "react";
import ReactGA from "react-ga4";


const Analytics = () => {
    useEffect(() => {
        ReactGA.initialize("G-J3B5L1QNGB")
    }, []);

    return null
};

export default Analytics;